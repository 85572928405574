<template>
  <div>
    <div class="title-panel">
      <b-row>
        <b-col md="6" class="text-left"> Photo </b-col>
        <b-col md="6" class="text-right">
          <span v-if="id !== 0">
            Latest Update {{ $moment(form.updated_time).format("DD/MM/YYYY") }} ({{
            $moment(form.updated_time).format("HH:mm:ss")
            }})
          </span>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white p-3">
      <b-row>
        <b-col>
          <div
            class="preview-box"
            v-bind:style="{
              'background-image': 'url(' + form.image_url + ')',
            }"
          >
            <img
              src="@/assets/icons/loading.svg"
              class="loading"
              alt="loading"
              v-if="isLoadingImage"
            />
            <div class="text-right">
              <font-awesome-icon
                icon="times-circle"
                class="text-secondary delete-icon pointer"
                v-if="form.image_url"
                @click="onDeleteImage"
              />
            </div>
          </div>
        </b-col>
        <b-col>
          <UploadFile
            v-model="form.image_url"
            :value="form.image_url"
            textFloat="Cover Photo"
            placeholder="Please select a file to upload."
            format="image"
            name="thumbnail"
            accept="image/jpeg, image/png"
            text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
            isRequired
            v-on:onFileChange="onImageChange"
            :fileName="form.image_url"
            :v="v.form.image_url"
            :isValidate="v.form.image_url.$error"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
export default {
  components: {
    UploadFile,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isLoadingImage: false,
      isDisable: false,
    };
  },
  methods: {
    onDeleteImage() {
      this.form.image_url = "";
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.image_url = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
        is_video: false,
        type: 0,
      };
      await this.$store.dispatch("uploadProductImage", form);
      var data = await this.$store.state.product.stateUploadProductImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-custom {
  background-color: var(--secondary-color);
  color: #000;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
}
.img-box-preview {
  width: 100%;
  padding-bottom: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  border: 2px dashed #bfbfbf;
  position: relative;
}
.update-least {
  color: #767676;
  font-weight: 100;
}
</style>
